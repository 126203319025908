export default function() {
  document.querySelectorAll('.ginput_container input, .ginput_container textarea').forEach(function (input) {
    input.addEventListener('focus', function () {
      var label = this.parentNode.previousSibling;
      label.classList.add('focus');
      label.classList.add('not-empty');
    });
    input.addEventListener('blur', function () {
      var label = this.parentNode.previousSibling;
      if (this.value == '') {
        label.classList.remove('not-empty');
      }
      label.classList.remove('focus');
    });
  });
}
