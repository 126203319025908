export default function() {
  if (document.querySelector('.filter-button')) {
    document.querySelector('.filter-button').addEventListener('click', function (){
      if (this.nextElementSibling.classList.contains('open')) {
        this.classList.remove('upsidedown');
        this.nextElementSibling.classList.remove('open');
        this.nextElementSibling.classList.add('closed');
      } else {
        this.classList.add('upsidedown');
        this.nextElementSibling.classList.remove('closed');
        this.nextElementSibling.classList.add('open');
      }
    });
  }

  if (document.querySelector('.ht-vacancy-filter')) {
    document.querySelectorAll('.ht-vacancy-filter input[type="checkbox"]').forEach(function (box) {
      box.addEventListener('click', function () {
        filterJobs();
      });
    });
  }

  function filterJobs() {
    var cats = [];
    document.querySelectorAll('.ht-vacancy-filter input[type="checkbox"]').forEach(function (box) {
      if (box.checked) {
        cats.push(box.value);
      }
    });
    if (cats.length > 0) {
      // hide all jobs
      document.querySelectorAll('.ht-vacancy').forEach(function (job) {
        job.style.display = 'none';
      });
      // loop over categories and show relevant jobs
      for (var i = 0; i < cats.length; i++) {
        document.querySelectorAll('.ht-vacancy[data-type="' + cats[i] + '"]').forEach(function (job) {
          job.style.display = 'block';
        });
      }
    } else {
      // show all jobs
      document.querySelectorAll('.ht-vacancy').forEach(function (job) {
        job.style.display = 'block';
      });
    }
  }

  // toggle parent category if subcategory is selected
  // on click
  var hasACheck = [];
  document.querySelectorAll('.ht-vacancy-filter > form > ul > li > ul > li > input').forEach(function (elem) {
    if (elem.checked) {
      hasACheck.push(elem);
    }
    elem.addEventListener('click', function (e) {
      if (this.checked) {
        this.parentNode.parentNode.parentNode.classList.add('toggled');
      } else {
        if(hasACheck.length <= 1) {
          this.parentNode.parentNode.parentNode.classList.remove('toggled');
        }
      }
    });
  });
  
  // on load
  document.addEventListener('DOMContentLoaded', function () {
    var check = document.querySelector('.ht-vacancy-filter > form > ul > li > ul > li > input:checked');
    if (check !== null) {
      check .parentNode.parentNode.parentNode.classList.add('toggled');
    }
  });
}
