import $ from 'jquery';

export default function() {
    var txt = $('textarea.textarea.large'),
      hiddenDiv = $(document.createElement('div')),
      content = null;
    txt.addClass('txtstuff');
    hiddenDiv.addClass('hiddendiv common');
    $('body').append(hiddenDiv);
    if (hiddenDiv.height() < 130) {
      txt.on('keyup', function () {
        content = $(this).val();
        content = content.replace(/\n/g, '<br>');
        hiddenDiv.html(content + '<br class="lbr">');
        $(this).css('height', hiddenDiv.height());
        $(this).parent().css('height', hiddenDiv.height());
      });
    }
}
