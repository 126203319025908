export default function() {
  if(document.querySelector('.ht-global__cookies') != null) {
    document.querySelector('.ht-cookie__standard').addEventListener('click', function () {
      document.querySelector('.ht-cookie__custom').classList.remove('selected');
      this.classList.add('selected');
      document.querySelector('#analytics-cookies').setAttribute('disabled', 'disabled')
      document.querySelector('#analytics-cookies').classList.add('disabled');
    });

    document.querySelector('.ht-cookie__custom').addEventListener('click', function () {
      document.querySelector('.ht-cookie__standard').classList.remove('selected');
      this.classList.add('selected');
      document.querySelector('#analytics-cookies').removeAttribute('disabled')
      document.querySelector('#analytics-cookies').classList.remove('disabled');
    });
  }
}
