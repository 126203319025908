export default function() {
  const bodyScrollLock = require('body-scroll-lock');
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;
    
  // 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav). 
  const targetElement = document.querySelector('.mobile-nav-list.open');
  const targetElement2 = document.querySelector('.mobile-nav-list.open ul.ht-nav--main');
    
  document.querySelector('.mobile-nav-toggle').addEventListener('click', function (e) {
    if (document.querySelector('.mobile-nav-list').classList.contains('closed')) {
      document.querySelector('.mobile-nav-list').classList.remove('closed');
      document.querySelector('.mobile-nav-list').classList.add('open');
      document.querySelector('.mobile-nav-toggle i').classList.remove('fa-bars');
      document.querySelector('.mobile-nav-toggle i').classList.add('fa-times');
      document.querySelector('html, body').classList.add('locked');
      disableBodyScroll(targetElement);
      disableBodyScroll(targetElement2);
    } else {
      document.querySelector('.mobile-nav-list').classList.remove('open');
      document.querySelector('.mobile-nav-list').classList.add('closed');
      document.querySelector('.mobile-nav-toggle i').classList.remove('fa-times');
      document.querySelector('.mobile-nav-toggle i').classList.add('fa-bars');
      document.querySelector('html, body').classList.remove('locked');
      enableBodyScroll(targetElement);
      enableBodyScroll(targetElement2);
    }
  });

  if (window.innerWidth < 1025) {
    addArrows();
  } else {
    removeArrows();
  }

  function addArrows() {
    document.querySelectorAll('.menu-item-has-children').forEach(function (el) {
      if (el.querySelector('span.toggle-sub-menu') === null) {
        var arrow = document.createElement('span');
        arrow.innerHTML = '<i class="fas fa-chevron-down"></i>';
        arrow.classList.add('toggle-sub-menu');
        el.querySelector('a').appendChild(arrow);
      }
    });
  
    document.querySelectorAll('.toggle-sub-menu').forEach(function (elem) {
      elem.addEventListener('click', function (e) {
        e.preventDefault();
        elem.parentElement.classList.toggle('open');
        elem.parentElement.nextElementSibling.classList.toggle('open');
      })
    });
  }

  function removeArrows() {
    document.querySelectorAll('.menu-item-has-children a').forEach(function (el) {
      if(el.querySelector('span.toggle-sub-menu') !== null) {
        el.querySelector('span.toggle-sub-menu').remove();
      }
    });
  }
}
