export default function() {
  window.setCookie = function (name,value,days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
  window.getCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  window.onload = function () {
    if (document.querySelector('.ht-cookie__bar') !== null) {
      document.querySelector('.ht-cookie').style.marginTop = document.querySelector('.ht-cookie__bar').clientHeight + 'px';
    }

    window.addEventListener('resize', function () {
      if (document.querySelector('.ht-cookie__bar') !== null) {
        document.querySelector('.ht-cookie').style.marginTop = document.querySelector('.ht-cookie__bar').clientHeight + 'px';
      }
    });

    if(document.querySelector('.ht-cookie__agree') != null) {
      document.querySelector('.ht-cookie__agree').addEventListener('click', function () {
        setCookie('EuCookieBanner','yes',365);
        location.reload(true);
      });
    }

    if (document.querySelector('.ht-cookie') != null) {
      document.querySelector('.ht-cookie').parentElement.removeAttribute('style');
    }
    
    var cookieValue = window.getCookie('EuCookieBanner');
    var sc = document.getElementById('functional-cookies');
    var ac = document.getElementById('analytics-cookies');
    if(document.querySelector('.ht-cookie__save-cookie') != null) {
      var save =  document.querySelector('.ht-cookie__save-cookie');
    }

    if(cookieValue) {
      var has =  function (a, b) {
        return a.indexOf(b) !== -1;
      }

      if (document.querySelector('.ht-cookie__settings') != null) {
        sc.checked = has(cookieValue, 'functional') || has(cookieValue, 'yes');
        ac.checked = has(cookieValue, 'analytics') || has(cookieValue, 'yes');

        if(sc.checked) {
          document.querySelector('input#functional-cookies').setAttribute('checked', 'checked');
        }

        if(ac.checked) {
          document.querySelector('input#analytics-cookies').setAttribute('checked', 'checked');
        }
      }
    }

    if(document.querySelector('.ht-cookie__save-cookie') != null) {
      save.addEventListener('click', function (e) {
        document.querySelector('.ht-cookie__snackbar').classList.add('ht-cookie__snackbar--show');

        if(sc.checked && ac.checked) {
          window.setCookie('EuCookieBanner', 'yes', 365);
          location.reload(true);
        }

        var cookieString = [];
        if(sc.checked) {
          cookieString.push('functional');
        }
        if(ac.checked) {
          cookieString.push('analytics');
        }
        if(cookieString.length === 0) {
          window.setCookie('EuCookieBanner', 'none', 365);
          location.reload(true);
        }
        window.setCookie('EuCookieBanner', cookieString.join('-'), 365);

        setTimeout(function () {
          location.reload(true);
        }, 2000);
      });
    }
  };
};
