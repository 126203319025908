import polyfill from './parts/_polyfill';
polyfill();

import $ from 'jquery';
import 'lazysizes';
import Clipboard from 'clipboard';
import testimonialSlider from './parts/_testimonial-slider';
import SimpleLightbox from 'simple-lightbox';
import mobileNavToggle from './parts/_mobile-nav-toggle';
import filterToggle from './parts/_filter';
import cookieSettings from './parts/_cookieSettings';
import cookies from './parts/_cookies';
import inputMaterialize from './parts/_input-materialize';
import textAreaAutoSize from './parts/_text-area-autosize';

testimonialSlider();
inputMaterialize();
textAreaAutoSize();
mobileNavToggle();
filterToggle();
cookieSettings();
cookies();

if (document.querySelector('.ht-video') !== null) {
  new SimpleLightbox({ elements: '.ht-video-link' });
}

if (document.querySelector('.button-apply') !== null && window.innerWidth >= 600) {
  document.querySelector('.button-apply').addEventListener('click', function (e) {
    e.preventDefault();
    var source = document.querySelector('.button-apply').getAttribute('href').replace('http://', 'https://');
    SimpleLightbox.open({
      content: '<iframe class="slbIframe application-form" frameborder="0" allowfullscreen="" src="' + source + '"></iframe>',
      elementClass: 'slbContentEl'
    });
  });
}

if (document.querySelector('.button-alert') !== null && window.innerWidth >= 600) {
  document.querySelector('.button-alert').addEventListener('click', function (e) {
    e.preventDefault();
    var source = document.querySelector('.button-alert').getAttribute('href');
    SimpleLightbox.open({
      content: '<iframe class="slbIframe application-form" frameborder="0" allowfullscreen="" src="' + source + '"></iframe>',
      elementClass: 'slbContentEl'
    });
  });
}

if (document.querySelector('.ht-share-buttons') !== null) {
  window.cp = new Clipboard('.copy-link', {
    text: function(trigger) {
      trigger.classList.add('copied');
      setTimeout(function() {
        trigger.classList.remove('copied');
      }, 500);
      return trigger.getAttribute('data-copy');
    }
  });
}

if (document.querySelector('.ht-faq') !== null) {
  document.querySelectorAll('.ht-faq--item .q').forEach(function(item) {
    item.addEventListener('click', function() {
      if (this.parentNode.classList.contains('open')) {
        this.parentNode.classList.remove('open');
        this.parentNode.classList.add('closed');
      } else {
        this.parentNode.classList.remove('closed');
        this.parentNode.classList.add('open');
      }
    });
  });
}




/*
*/

$( document ).ready(function() {    
  //Pagination
  var pageSize = 10;
  var incremSlide = 10;
  var startPage = 0;
  var numberPage = 0;

  var pageCount =  $(".ht-vacancy").length / pageSize;
  var totalSlidepPage = Math.floor(pageCount / incremSlide);
  
  for(var i = 0 ; i<pageCount;i++){
      $("#pagin ul").append('<li><a href="#">'+(i+1)+'</a></li> ');
      if(i>pageSize){
        $("#pagin ul li").eq(i).hide();
      }
  }

  var prev = $("<li/>").addClass("prev").html('<span><i class="fal fa-chevron-left"></i></span>').click(function(){
    startPage-=5;
    incremSlide-=5;
    numberPage--;
    slide();
  });
  prev.hide();

  var next = $("<li/>").addClass("next").html('<span><i class="fal fa-chevron-right"></i></span>').click(function(){
    startPage+=5;
    incremSlide+=5;
    numberPage++;
    slide();
  });
  $("#pagin ul").prepend(prev).append(next);

  $("#pagin ul li").first().find("a").parent('li').addClass("active");

  function slide(sens){
    $("#pagin ul li").hide();
    var t;
    for(t=startPage;t<incremSlide;t++){
      $("#pagin ul li").eq(t+1).show();
    }
    if(startPage == 0){
      next.show();
      prev.hide();
    }else if(numberPage == totalSlidepPage ){
      next.hide();
      prev.show();
    }else{
      next.show();
      prev.show();
    } 
  }

  function showPage(page) {
    $(".ht-vacancy").hide();
    $(".ht-vacancy").each(function(n) {
        if (n >= pageSize * (page - 1) && n < pageSize * page)
            $(this).show();
    });        
  }
    
  showPage(1);
  $("#pagin ul li a").eq(0).parent('li').addClass("active");

  $("#pagin li a").click(function() {
    $("#pagin ul li a").parent('li').removeClass("active");
    $(this).parent('li').addClass("active");
    showPage(parseInt($(this).text()));
  });
});