import $ from 'jquery';
import './slick.min';

export default function() {
    $('.ht-testimonial--slider').slick({
      dots: true,
      slidesToShow: 1,
      prevArrow: '<button class="slick-prev" type="button"><i class="fas fa-chevron-left"></i></button>',
      nextArrow: '<button class="slick-next" type="button"><i class="fas fa-chevron-right"></i></button>',
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [{
          breakpoint: 736,
          settings: {
            arrows: false,
            centerPadding: '48px',
            slidesToShow: 1,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerPadding: '48px',
            slidesToShow: 1,
            dots: true
          }
        }
      ]
    });
}
